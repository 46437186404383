
import { Options, Vue, prop } from 'vue-class-component'
import Preview from '@/components/PreviewComponent/Preview.vue'
import { DatosCrypto, FileEntity } from '@pacprotocol/yanui'
import mimeTypes from 'mime-types'

import {
    YButton,
    YModal,
    YSlider,
    YDropdown,
    YDropdownItem,
} from '@pacprotocol/yanui'

@Options({
    components: {
        YModal,
        YButton,
        YSlider,
        YDropdownItem,
        YDropdown,
        Preview,
    },
})
export default class PublicDownload extends Vue {
    public file: FileEntity | any = null
    public user_id: string = ''
    public file_id: string = ''
    public password?: CryptoKey
    public file_name: string = ''
    public encryption_loaded: boolean = false

    async public_download() {
        this.$utils.file_public_download(this.file, this.user_id)
        const is_logged = this.$auth0?.user?.value ?? false
        ;(this.$i ? this.$i : this).$plausible.trackEvent(
            'Shared File Download',
            {
                props: {
                    link: window.location.href,
                    is_logged: is_logged.toString(),
                    file_size: this.$yanui.file_utils.byte_to_human_readable(
                        this.file.size,
                    ),
                },
            },
        )
    }

    async created() {
        this.user_id = this.$route.params.userid as string
        this.file_id = this.$route.params.fileid as string
        const hash = this.$route.hash.substring(1)
        const hash_split = hash.split(',')
        const password_raw = hash_split[0]
        this.file_name = hash_split[1]

        this.file_id = Buffer.from(this.file_id, 'base64').toString('hex')
        this.user_id = Buffer.from(this.user_id, 'base64').toString('hex')
        this.file_name = Buffer.from(this.file_name, 'base64').toString('utf8')
        document.title = this.file_name + ' - DatosDrive'
        this.password = await DatosCrypto.createCryptoKeyFromRawKey(
            new Uint8Array(Buffer.from(password_raw, 'base64')),
        )

        console.log(
            'file_id',
            this.file_id,
            'user_id',
            this.user_id,
            'file_name',
            this.file_name,
            'password',
            this.password,
        )

        const { size } = await this.$utils.file_public_size(
            this.file_id,
            this.user_id,
        )

        this.file = {
            id_sha256: this.file_id,
            name: this.file_name,
            key: this.password,
            size,
        }

        await this.$yanui.file_server.file_meta_add({
            id: this.file.id_sha256,
            mimetype:
                mimeTypes.lookup(this.file.name) || 'application/octet-stream',
            name: this.file.name,
            size,
            key: this.file.key,
            type: '',
            public: true,
            userid: this.user_id,
        })
        this.encryption_loaded = true
    }
}
