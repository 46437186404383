import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "public-view-wrapper" }
const _hoisted_2 = { class: "file-name-title-slider" }
const _hoisted_3 = { class: "file-size-title-slider secondary-text secondary-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_button = _resolveComponent("y-button")!
  const _component_Preview = _resolveComponent("Preview")!
  const _component_y_slider = _resolveComponent("y-slider")!
  const _component_y_modal = _resolveComponent("y-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.file)
      ? (_openBlock(), _createBlock(_component_y_modal, {
          key: 0,
          active: true,
          "append-class": "is-slider is-slider-public",
          "has-card": false,
          overflow: "visible",
          "modal-inner": false,
          "can-cancel": false,
          "can-cancel-background": false,
          "has-animation": false,
          "no-space": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_y_slider, {
              active: true,
              id: _ctx.file ? _ctx.file.id : null,
              "has-slider": false,
              "no-space": true
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.file.name), 1),
                _createElementVNode("span", _hoisted_3, " - " + _toDisplayString(_ctx.$yanui.file_utils.byte_to_human_readable(_ctx.file.size)), 1)
              ]),
              action: _withCtx(() => [
                _createVNode(_component_y_button, {
                  type: "is-success",
                  icon: "cloud-download",
                  spacing: true,
                  onClick: _ctx.public_download
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Download ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              default: _withCtx(() => [
                (_ctx.encryption_loaded)
                  ? (_openBlock(), _createBlock(_component_Preview, {
                      key: 0,
                      file: _ctx.file
                    }, null, 8, ["file"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["id"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}